<template>
  <div class="setmeal">
    <NavBar v-if="isWeixin_status == false" fixed left-arrow @click-left="goBack" title="分享"></NavBar>
    <img src="@static/home/shareBg.jpg" alt="">
  </div>
</template>
<script>
import { NavBar} from "vant";
export default {
  components: {
    NavBar
  },
  methods: {
   goBack() {
      this.$router.go(-1)
    },
  }
};
</script>
<style lang="scss" scoped>
.setmeal {
  width: 100%;
  height: 100vh;
  padding-top: 50px;
  background: rgba(244, 244, 244, 1);
  overflow-y: scroll;
  overflow-x: hidden;
  // overflow: hidden;
 img {
   width: 100%;
 }
}
</style>

<style lang="scss">
.setmeal {

}
</style>
